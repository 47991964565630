<template>
  <v-app>
    <home-menu
      v-if="!userType || userType === ANONYMOUS"
      :pro="pro"
    />
    <enduser-menu
      v-if="userType === ENDUSER"
    />
    <service-provider-menu
      v-if="userType === SERVICEPROVIDER"
    />

    <kdw-Statusbar-card
      ref="kdwsnackbar"
    />

    <common-view />

    <common-footer />
  </v-app>
</template>

<script>
  import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
  import { Logger } from 'aws-amplify'
  import { ANONYMOUS, ENDUSER, SERVICEPROVIDER, getUserType, onUserTypeChange } from '@/services/User'
  const logger = new Logger('HomeLayout')

  @Component({
    name: 'HomeLayout',
    components: {
      HomeMenu: () => import('@/layouts/home/Menu'),
      EnduserMenu: () => import('@/layouts/enduser/Menu'),
      ServiceProviderMenu: () => import('@/layouts/serviceprovider/Menu'),
      CommonFooter: () => import('@/layouts/Footer'),
      CommonView: () => import('@/layouts/View'),
    },
  })
  class HomeLayout extends Vue {
    ANONYMOUS = ANONYMOUS
    ENDUSER = ENDUSER
    SERVICEPROVIDER = SERVICEPROVIDER

    created () {
      this.ANONYMOUS = ANONYMOUS
      this.ENDUSER = ENDUSER
      this.SERVICEPROVIDER = SERVICEPROVIDER
      this.userType = getUserType()
      this.unsubscribeUserType = onUserTypeChange((newUserType) => {
        logger.info('changing userType ', newUserType)
        this.userType = newUserType
      })
    }

    @Watch('userType')
    // needed to force a re-rendering ?
    onUserTypeChanged (newV, oldV) {
      logger.info(`changed userType from ${oldV} to ${newV}`)
    }

    @Prop({ default: false }) pro

    data () {
      return {
        ENDUSER: null,
        ANONYMOUS: null,
        SERVICEPROVIDER: null,
        unsubscribeUserType: null,
        userType: null,
      }
    }

    mounted () {
      this.$root.kdwsnackbar = this.$refs.kdwsnackbar
    }

    beforeDestroy () {
      logger.info('Unsubscribed')
      this.unsubscribeUserType()
    }
  }

  export default HomeLayout
</script>
